// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #0369a1;
$secondary: #38bdf8;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;
$light: #f7f7f7;
$dark: #292b2c;

//custom color to be mapped
$accent : #ff0000;
$muted: #D3D3D3;
$highlight: #f2c602;
$specialBlue: #0369a1;
$progressBar: #A3A3A3;


//this is used to map colors. change accordingly.
$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger,
    light: $light,
    dark: $dark,
    // add any additional color below
    accent: $accent,
    muted: $muted,
    highlight: $highlight,
    specialBlue: $specialBlue,
    progressBar: $progressBar,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "react-datepicker/dist/react-datepicker.css";
@import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import 'react-calendar/dist/Calendar.css';