* {
  font-family: "Inter", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title {
  font-weight: bolder;
  margin-bottom: 0.5em;
}

.title2 {
  font-weight: bolder;
  margin-bottom: 1em;
  margin-top: 1em;
}

.title3 {
  font-weight: bolder;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.btn-noBackground {
  background-color: none;
}

.noChangeOnClick:active {
  background-color: lightgray;
}

.italic {
  font-style: italic;
}

.smallcaps {
  font-variant: small-caps;
}

.item-container {
  display: flex;
  overflow-x: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
}

.cartaSommarioBorder {
  border-top: 10px solid;
  border-left: 3px solid;
  border-right: 3px solid;
  border-bottom: 3px solid;
  width: 100%;
}

.cartaSommario {
  border-top: 10px solid;
  border-left: 3px solid;
  border-right: 3px solid;
  border-bottom: 3px solid;
  width: 100%;
}

.divider {
  border: 1px solid #38bdf8;
}

.krmNah {
  background-color: #0369a1 !important;
  box-shadow: 0 0 1px 2px #0369a1 !important;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 2px rgba(235, 16, 16, 0.5);
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
  background-color: transparent !important;
  font-size: 1.6em !important;
  box-shadow: none !important;
}

.rec.rec-arrow:focus:enabled,
.rec.rec-arrow:enabled,
.rec.rec-arrow:hover:enabled {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rec.rec-item-wrapper {
  height: 100%;
}

/* dimensione delle frecce */
.eWjCzc {
  font-size: 1.6em !important;
  color: #282c34 !important
}

.dark {
  color: aliceblue;
}

.light {
  color: black
}

.smallText {
  font-size: 12px;
}

.pillText {
  font-size: 13px;
}

.mediumText {
  font-size: 18px;
}

.bigText {
  font-size: 22px
}

.text-justify {
  text-align: justify !important;
}

.shadow {
  box-shadow: 1px, 1px, 1px, muted;
}

.searchWrapper {
  background-color: white !important;
}

.searchWrapper.invalid {
  border: 1px solid red;
}

.searchWrapper.ok {
  border: 1px solid #5cb85c;
}

.moduleLink {
  border: #0369a1 1px solid;
}

.borderTitle:hover {
  border-bottom: #acacac 1px solid;
  cursor: pointer;
}

.borderTitle {
  border: white 1px solid;
}

.moduleLink:hover {
  /* color: red !important; */
  border-bottom: whitesmoke 1px solid;
  border-top: whitesmoke 1px solid;
}

.voteButton {
  min-height: 3em;
  min-width: 3em;
  font-size: 2em;
  background-color: white;
  line-height: 2.5em;
}

.votemodal {
  min-height: 50vh;
  outline: 10px solid #ffe566;
  background-color: #fff2b2;
}

.referendumInfo {
  padding: 24px;
  border: black 1px solid;
  background-color: white;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.segnoVoto {
  opacity: 0%;
}

.segnoVoto:hover {
  opacity: 100%;
  transition: opacity 1.30s;
}

.modalPermessi {
  border: 2px solid;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.allegatoSegnalazione:hover {
  cursor: pointer;
}

.right {
  text-align: right
}

.underline {
  text-decoration: underline
}

.nowrap {
  white-space: nowrap;
}

.no-resize {
  resize: none
}

.inline {
  display: inline
}

/* Stili customizzati per elementi badge.badge-categorie: START */
.badge.badge-categorie {
  margin-left: 10px;
  color: black;
  border: 1px solid black;
}

.badge.badge-categorie .container-xs {
  max-width: 90%;
}

.badge.badge-categorie button {
  border-radius: 15px;
  padding: 0px;
}

/* Stili customizzati per elementi badge.badge-categorie: END */

/* Stile per colorare l'opzione selezionata in un campo Multiselect con classe .multiselect-style1*/
.multiselect-style1 .optionContainer .option.selected {
  background-color: #0096fb
}

.multiselect-style1 span.chip {
  font-size: 1rem;
  margin-bottom: 0;
}

/* Stile custom per arrotondamento a 1.5rem */
.cm-rounded-1_5 {
  border-radius: 1.5rem !important;
}

/* Campi datePicker con custom-validation - START */
.datepicker-cm-validation .form-control.is-valid {
  background-position: right calc(1.475em + 0.1875rem) center !important;
}

.datepicker-cm-validation .invalid-feedback:not(:empty) {
  display: block;
}

/* Campi datePicker con custom-validation - END */
.was-validated .form-control:valid.is-invalid,
.was-validated .form-control:valid:focus.is-invalid,
.multiselect-container.is-invalid .searchWrapper,
.css-1s2u09g-control.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-image: none;
  box-shadow: 0 0 0 0.25rem rgb(217 83 79 / 25%);
}

.was-validated .form-control:valid.is-valid,
.multiselect-container.is-valid .searchWrapper,
.css-1s2u09g-control.is-valid {
  border-color: #5cb85c;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-image: none;
  box-shadow: 0 0 0 0.25rem rgb(92 184 92 / 25%);
}

.invalid-feedback.block {
  display: block
}

.val-cnt-1,
.val-cnt-2 {
  position: relative;
}

.val-cnt-1 .icon,
.val-cnt-2 .icon {
  position: absolute;
}

.val-cnt-1 .icon {
  right: 32px;
  top: 8px
}

.val-cnt-2 .icon {
  right: 11px;
  top: 8px
}

table.allegatiProposta tr.toDelete td,
table.allegatiProposta tr.toDelete button.download {
  color: red
}

table.allegatiProposta tr.toDelete button.resume {
  color: #5cb85c
}

.required-alert {
  font-size: 0.9em
}

.sidebar button img,
.sidebar-listGroup button img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.SPIDformControl {
  background-color: #e8f0fe !important;
  border: none;
  border-bottom: 1px solid lightgray;
}

.SPIDbutton {
  border-radius: 20px;
}

.cartaCruscotto {
  color: white;
  background-image: linear-gradient(30deg, #0369a1, #38bdf8);
  border: white;
  height: 20vh;
}

.delete-fase {
  position: absolute;
  right: 24px;
  top:12px
}


@media (min-width: 992px) {
  .card-height-lg {
    height: 100%;
  }
}


.slick-prev:before,
.slick-next:before {
  color: black ;
}



.slick-slide {
  padding: 5px;
}

.row-blocked {
  background-color: #f0f0f0 !important; /* Colore grigio chiaro */
  color: #6c757d !important; /* Colore del testo grigio per indicare lo stato bloccato */
}

.react-daterange-picker__inputGroup{
  text-align: center;
}

.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top:100px;
  align-self: start; 
}